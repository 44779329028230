import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { v4 } from "uuid";
import { FocusError } from "focus-formik-error";
import firebase from "firebase/app";
import Modal from '@mui/material/Modal';
// material
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

// utils
import { getAnYearAheadDate } from "../../../utils/helpers";
import fakeRequest from "utils/fakeRequest";
import {
  editCodeOrCreateInvitationCode,
  duplicateInvitationCode,
  prependItem,
} from "../../../utils/firebase";

// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

// @types
import {
  InvitationCode,
  SupportedLanguages,
} from "../../../types/invitation/code";

// components & pages
import Label from "../../Label";
import DynamicSupportContacts from "./code-configs/DynamicSupportContacts";
import { NewInvitationCodeSchema } from "./schemas/InvitationCode";
import { LanguageSelectionDropDown } from "../../LanguageSelectionDropDown";
import { ExclusiveSituations } from "./code-configs/ExclusiveSitationsControl";
import { SupportedLanguagesEnum } from "../../../utils/constants";
import DemographicQuestions from "./demographicQuestions";
import CodeUsersPopover from "../registration-codes/CodeUsersPopover";
import DynamicPaymentLinks from "./code-configs/DynamicPaymentLinks";

import DeleteIcon from "@mui/icons-material/Delete";
import {isValidDomain} from "../../../utils/validDomain";

const InlinedPre = styled("pre")({
  display: "inline",
});

type InvitationCodeNewFormProps = {
  isEdit: boolean;
  isView?: boolean;
  isDuplicate?: boolean;
  currentCode?: InvitationCode;
};

interface ModalState {
  isOpen: boolean;
  type: 'confirm' | 'add';
  selectedItemForDelete?: string;
  newDomainValue?: string;
}

const initialModalState: ModalState = {
  isOpen: false,
  type: 'confirm',
};

export default function InvitationNewForm({
  isView,
  isEdit,
  isDuplicate,
  currentCode,
}: InvitationCodeNewFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedSCLang, setSelectedSCLang] = useState<SupportedLanguages>(
    SupportedLanguagesEnum.ENGLISH
  );
  const [selectedCreditsInfoMessageLang, setSelectedCreditsInfoMessageLang] =
    useState<SupportedLanguages>(SupportedLanguagesEnum.ENGLISH);
  const [isDomainEnable, setIsDomainEnable] = useState(currentCode?.whiteListDomains?.enabled);
  const [modal, setModal] = useState<ModalState>(initialModalState);
  const [isShowError, setIsShowError] = useState<boolean>(false);
  const [domains, setDomains] = useState<string[]>(
      currentCode?.whiteListDomains?.domains || []
  );
  const openModal = (type: 'confirm' | 'add', selectedItemForDelete?: string) => {
    setModal({ isOpen: true, type, selectedItemForDelete });
  };
  const closeModal = () => {
    setModal(initialModalState);
    setIsShowError(false)
  };
  const handleOkay = () => {
    if (modal.type === 'confirm' && modal.selectedItemForDelete) {
      setDomains(prevDomains => {
        return prevDomains.filter(domain => domain !== modal.selectedItemForDelete)
       }
      );
    } else if (modal.type === 'add' && modal.newDomainValue) {
      if (!isValidDomain(modal.newDomainValue)) {
        setIsShowError(true);
        return
      } else {
        setDomains((prevDomains) => {
          const newDomain = modal.newDomainValue;
          if (typeof newDomain === 'string' && newDomain.trim() !== '' && !prevDomains.includes(newDomain.trim())) {
            return [...prevDomains, newDomain.trim()];
          }
          return prevDomains;
        });
      }
    }
    closeModal();
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModal({ ...modal, newDomainValue: event.target.value });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: currentCode?.code || "",
      usageLimit: currentCode?.usageLimit || 0,
      trackPrivateInfo: currentCode?.trackPrivateInfo ?? false,
      translations: currentCode?.translations ?? undefined,
      videoCall: currentCode?.videoCall ?? undefined,
      chat: currentCode?.chat ?? undefined,
      questions: currentCode?.questions || undefined,
      demographicQuestions: currentCode?.demographicQuestions || undefined,
      supportContacts: currentCode?.supportContacts || undefined,
      creditsInfoMessage: currentCode?.creditsInfoMessage,
      paymentLinks: currentCode?.paymentLinks ?? [],
      suspended: currentCode?.suspended ?? false,
      usernameAllowed: currentCode?.usernameAllowed ?? false,
      matchCounsellorInOnboarding:
        currentCode?.matchCounsellorInOnboarding ?? false,
      expiresAt:
        currentCode?.expiresAt?.toDate?.()?.toISOString()?.split?.("T")[0] ||
        getAnYearAheadDate().toISOString().split("T")[0],
      createdAt:
        currentCode?.createdAt?.toDate?.()?.toISOString()?.split?.("T")[0] ||
        new Date().toISOString().split("T")[0],
      timesChecked: currentCode?.timesChecked ?? 0,
      exclusiveSituations: currentCode?.exclusiveSituations || [],
      searchRestrictionFilter: currentCode?.searchRestrictionFilter ?? "",
      isCounsellingBoosterEnabled:
        currentCode?.isCounsellingBoosterEnabled ?? false,
      companySpecificHotline: currentCode?.companySpecificHotline ?? false,
      enforceDemographicQuestions:
        currentCode?.enforceDemographicQuestions ?? false,
      company: {
        name: currentCode?.company?.name ?? "",
        description: currentCode?.company?.description ?? "",
      },
      whiteListDomains: {
        enabled : domains?.length > 0 ? isDomainEnable : false ,
        domains : domains ?? []
      },
      disclaimer: currentCode?.disclaimer ?? undefined,
    },
    validationSchema: NewInvitationCodeSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        const finalData: InvitationCode = {
          ...(values || {}),
          expiresAt: firebase.firestore.Timestamp.fromDate(
            new Date(values?.expiresAt ?? "")
          ),
          createdAt: firebase.firestore.Timestamp.fromDate(
            new Date(values?.createdAt ?? "")
          ),
          translations: {
            enableTranslations: true,
          },
          oldInvitationCode: currentCode?.oldInvitationCode || "",
          whiteListDomains: {
            ...values?.whiteListDomains,
            enabled: values?.whiteListDomains?.enabled ?? false,
          },
        };

        if (
          !finalData.chat?.enableChat &&
          finalData.videoCall?.enableVideoCall
        ) {
          const confirmed = window.confirm(
            "It looks like you enable video calls but disable chats. Are you sure this is correct? You can expect UIs not working correctly and users being unable to chat."
          );

          if (!confirmed) {
            return;
          }
        }

        isDuplicate
          ? await duplicateInvitationCode(finalData)
          : await editCodeOrCreateInvitationCode(finalData, !isEdit);
        resetForm();
        setSubmitting(false);
        enqueueSnackbar(
          `Invitation code ${isEdit ? "saved" : "created"} successfully`,
          {
            variant: "success",
          }
        );
        await fakeRequest(200);
        navigate(PATH_DASHBOARD.invitationCodes.root);
      } catch (error) {
        setSubmitting(false);
        setErrors(error);
        enqueueSnackbar(
          "Failed to save the invitation code. Please try again.",
          {
            variant: "error",
          }
        );
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;
  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <fieldset disabled={isView} style={{ border: "none" }}>
          <FocusError formik={formik} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack spacing={2}>
                    <TextField
                      fullWidth
                      label="code"
                      {...getFieldProps("code")}
                      error={Boolean(touched.code && errors.code)}
                      helperText={touched.code && errors.code}
                    />

                    <TextField
                      fullWidth
                      label="Max. Uses"
                      {...getFieldProps("usageLimit")}
                      error={Boolean(touched.usageLimit && errors.usageLimit)}
                      helperText={touched.usageLimit && errors.usageLimit}
                    />

                    <CodeUsersPopover queryPathName={"invitationCode"} />

                    <TextField
                      fullWidth
                      label="Expiry Date"
                      {...getFieldProps("expiresAt")}
                      error={Boolean(touched.expiresAt && errors.expiresAt)}
                      helperText={touched.expiresAt && errors.expiresAt}
                      type="date"
                    />
                    {currentCode?.oldInvitationCode &&
                    <Typography variant="body1">
                      Duplicated from the invitation code: {currentCode?.oldInvitationCode}
                    </Typography>}
                  </Stack>
                  <Stack spacing={3}>
                    <Label sx={{ mt: 3, py: 2, justifyContent: "flex-start" }}>
                      Company information
                    </Label>
                    <TextField
                      fullWidth
                      label="Company name"
                      {...getFieldProps("company.name")}
                      error={Boolean(
                        touched.company?.name && errors.company?.name
                      )}
                      helperText={touched.company?.name && errors.company?.name}
                    />
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      label="Copany description"
                      {...getFieldProps("company.description")}
                      error={Boolean(
                        touched.company?.description &&
                          errors.company?.description
                      )}
                      helperText={
                        touched.company?.description &&
                        errors.company?.description
                      }
                    />
                  </Stack>
                  <Stack spacing={3}>
                    <Stack spacing={1}>
                      <Label
                        sx={{ mt: 3, py: 2, justifyContent: "flex-start" }}
                      >
                        Video Call
                      </Label>
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <Switch
                            onChange={(event) =>
                              setFieldValue(
                                "videoCall.enableVideoCall",
                                event.target.checked
                              )
                            }
                            checked={Boolean(
                              values?.videoCall?.enableVideoCall
                            )}
                          />
                        }
                        label={
                          <>
                            <Typography variant="subtitle2">
                              Enable All
                            </Typography>
                          </>
                        }
                        sx={{ justifyContent: "space-between" }}
                      />
                    </Stack>
                  </Stack>

                  <Stack spacing={3}>
                    <Stack spacing={1}>
                      <Label
                        sx={{ mt: 3, py: 2, justifyContent: "flex-start" }}
                      >
                        Chat
                      </Label>
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <Switch
                            onChange={(event) =>
                              setFieldValue(
                                "chat.enableChat",
                                event.target.checked
                              )
                            }
                            checked={Boolean(values?.chat?.enableChat)}
                          />
                        }
                        label={
                          <>
                            <Typography variant="subtitle2">
                              Enable all
                            </Typography>
                          </>
                        }
                        sx={{ justifyContent: "space-between" }}
                      />
                    </Stack>
                  </Stack>

                  <Stack spacing={3}>
                    <Stack spacing={1}>
                      <Label
                          sx={{ mt: 3, py: 2, justifyContent: "flex-start" }}
                      >
                        WhiteListed Domains
                      </Label>
                      <FormControlLabel
                          labelPlacement="start"
                          control={
                            <Switch
                                onChange={(event) => {
                                  setFieldValue(
                                      "whiteListDomains.enabled",
                                      event.target.checked
                                  )
                                  setIsDomainEnable(event.target.checked)
                                }

                                }
                                checked={Boolean(
                                    isDomainEnable
                                )}
                            />
                          }
                          label={
                            <>
                              <Typography variant="subtitle2">
                                Enable
                              </Typography>
                            </>
                          }
                          sx={{ justifyContent: "space-between" }}
                      />
                    </Stack>
                    {isDomainEnable && (
                        <>
                        <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'flex-start',
                              gap: 2
                            }}
                        >
                          {domains.map((item: string, index: number) => (
                              <Button
                                  key={index}
                                  sx={{alignSelf: 'flex-start'}}
                                  variant="contained"
                                  color={'inherit'}
                                  endIcon={<DeleteIcon />}
                                  onClick={() => openModal('confirm',item)}
                              >
                                {item}
                              </Button>
                          ))}
                        </Box>
                          <Button
                              sx={{alignSelf: 'flex-end'}}
                              variant="contained"
                              color={'success'}
                              endIcon={<AddIcon/>}
                              onClick={()=>{
                                openModal('add')
                              }}
                          >
                            Add Domain
                          </Button>
                        </>
                     )
                    }
                  </Stack>

                  <DemographicQuestions
                    getFieldProps={getFieldProps}
                    setFieldValue={setFieldValue}
                  />

                  <Stack spacing={3}>
                    <Label sx={{ mt: 3, py: 2, justifyContent: "flex-start" }}>
                      Support Contacts
                    </Label>
                    <LanguageSelectionDropDown
                      onSelectionChanged={(fieldKey, value) => {
                        // this is required because of when language is changed, if no unique key, field is not updating it's value.
                        if (
                          !getFieldProps(
                            `supportContacts.short_desc.${value}.id`
                          ).value
                        ) {
                          setFieldValue(
                            `supportContacts.short_desc.${value}.id`,
                            v4()
                          );
                        }
                        setSelectedSCLang(value);
                      }}
                      fieldName={"selectedSCLang"}
                      selectedValue={selectedSCLang}
                      label={"Select Language"}
                    />
                    <Stack>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={1}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <TextField
                          // @ts-ignore
                          key={
                            getFieldProps(
                              `supportContacts.short_desc.${selectedSCLang}.id`
                            ).value || "defaultKey"
                          }
                          fullWidth
                          label={`short description`}
                          {...getFieldProps(
                            `supportContacts.short_desc.${selectedSCLang}.value`
                          )}
                          onChange={(e) => {
                            // todo: make it more performant.
                            // reason to do this is field's value doesn't change on language change. sincewe need unique key for the element.

                            const id =
                              getFieldProps(
                                `supportContacts.short_desc.${selectedSCLang}.id`
                              ).value || v4();
                            setFieldValue(
                              `supportContacts.short_desc.${selectedSCLang}`,
                              {
                                id,
                                value: e?.target?.value || "",
                              }
                            );
                          }}
                          error={Boolean(
                            // @ts-ignore
                            touched?.supportContacts?.short_desc?.[
                              selectedSCLang
                            ]?.value &&
                              // @ts-ignore
                              errors?.supportContacts?.short_desc?.[
                                selectedSCLang
                              ]?.value
                          )}
                          helperText={
                            // @ts-ignore
                            touched?.supportContacts?.short_desc?.[
                              selectedSCLang
                            ]?.value &&
                            // @ts-ignore
                            errors?.supportContacts?.short_desc?.[
                              selectedSCLang
                            ]?.value
                          }
                        />
                      </Stack>
                    </Stack>
                    <Button
                      sx={{ alignSelf: "flex-end" }}
                      variant="contained"
                      color={"success"}
                      endIcon={<AddIcon />}
                      onClick={() => {
                        setFieldValue(
                          `supportContacts.${selectedSCLang}`,
                          prependItem(
                            {
                              id: v4(),
                              title: "",
                            },
                            getFieldProps(`supportContacts.${selectedSCLang}`)
                              ?.value || []
                          )
                        );
                      }}
                    >
                      Add Support Contact
                    </Button>

                    <Stack>
                      <DynamicSupportContacts
                        setFieldValue={setFieldValue}
                        getFieldProps={getFieldProps}
                        invitationCode={currentCode?.code}
                        featureName={"supportContacts"}
                        selectedLanguage={selectedSCLang}
                        touched={touched}
                        errors={errors}
                      />
                    </Stack>
                  </Stack>

                  <Stack spacing={3}>
                    <Label sx={{ mt: 3, py: 2, justifyContent: "flex-start" }}>
                      Payment Links
                    </Label>
                    <Button
                      sx={{ alignSelf: "flex-end" }}
                      variant="contained"
                      color={"success"}
                      endIcon={<AddIcon />}
                      onClick={() => {
                        setFieldValue(
                          `paymentLinks`,
                          prependItem(
                            {
                              id: v4(),
                              name: {
                                "en": ""
                              },
                              description: {
                                "en": ""
                              },
                              unitAmount: 0,
                              currency: "",
                              quantity: 1
                            },
                            getFieldProps(`paymentLinks`)?.value || []
                          )
                        );
                      }}
                    >
                      Add Payment Link
                    </Button>

                      <DynamicPaymentLinks
                      setFieldValue={setFieldValue}
                      getFieldProps={getFieldProps}
                      touched={touched}
                      errors={errors}
                        editing={isEdit}
                    />
                  </Stack>
                  <Stack spacing={3}>
                    <Label sx={{ mt: 3, py: 2, justifyContent: "flex-start" }}>
                      Credits Info
                    </Label>

                    <LanguageSelectionDropDown
                      onSelectionChanged={(fieldKey, value) => {
                        setSelectedCreditsInfoMessageLang(value);
                      }}
                      fieldName={"selectedCreditsInfoLang"}
                      selectedValue={selectedCreditsInfoMessageLang}
                      label={"Select Language"}
                    />

                    <TextField
                      key={`creditsInfoMessage.${selectedCreditsInfoMessageLang}`}
                      fullWidth
                      multiline
                      minRows={3}
                      label={`Credit info in ${selectedCreditsInfoMessageLang}`}
                      {...getFieldProps(
                        `creditsInfoMessage.${selectedCreditsInfoMessageLang}`
                      )}
                    />
                  </Stack>

                  <Stack spacing={2}>
                    <Label sx={{ mt: 3, py: 2, justifyContent: "flex-start" }}>
                      Search restrictions
                    </Label>
                    <p>
                      Some situations are not accessible to all customers. The
                      list below gives customer access to these situations. A
                      counsellor is only visible to a customer, if the customer
                      has access to <u>ALL</u> situations the counsellor
                      supports.
                    </p>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <ExclusiveSituations
                          setFieldValue={setFieldValue}
                          getFieldProps={getFieldProps}
                        />
                      }
                      label={
                        <>
                          <Typography variant="subtitle2">
                            Situations
                          </Typography>
                        </>
                      }
                      sx={{ width: 1, mx: 0, justifyContent: "space-between" }}
                    />

                    <p>
                      By default most counsellors are visible to a user (see
                      exclusive situations for exceptions). To hide some
                      counsellors based on facetted attributes add them below.
                      Use the format <InlinedPre>attribute:value</InlinedPre>.
                      Filters are chained with <InlinedPre>AND NOT</InlinedPre>.
                      Example:{" "}
                      <InlinedPre>
                        country:Germany,searchRestrictionTags:nonEu
                      </InlinedPre>{" "}
                      results in{" "}
                      <InlinedPre>
                        AND NOT country:Germany AND NOT
                        searchRestrictionTags:nonEu
                      </InlinedPre>
                      .
                    </p>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <TextField
                          fullWidth
                          {...getFieldProps("searchRestrictionFilter")}
                          value={values.searchRestrictionFilter
                            .replace(/^NOT /, "")
                            .replace(/ AND NOT /g, ",")}
                          error={Boolean(touched.expiresAt && errors.expiresAt)}
                          helperText={touched.expiresAt && errors.expiresAt}
                          onChange={(ev) => {
                            const value = ev.target.value.trim();
                            if (value.length === 0) {
                              setFieldValue("searchRestrictionFilter", "");
                              return;
                            }

                            const asFilter =
                              `NOT ` +
                              ev.target.value.replace(/,/g, " AND NOT ");
                            setFieldValue("searchRestrictionFilter", asFilter);
                          }}
                        />
                      }
                      label={
                        <Typography variant="subtitle2">
                          Additional filters
                        </Typography>
                      }
                      sx={{ width: 1, mx: 0, justifyContent: "space-between" }}
                    />
                  </Stack>

                  <Stack spacing={2}>
                    <Label sx={{ mt: 3, py: 2, justifyContent: "flex-start" }}>
                      Disclaimer
                    </Label>

                    <FormControlLabel
                        labelPlacement="start"
                        control={
                          <Switch
                            onChange={(event) =>
                              setFieldValue(
                                "disclaimer.isActive",
                                event.target.checked
                              )
                            }
                            checked={Boolean(
                              values?.disclaimer?.isActive
                            )}
                          />
                        }
                        label={
                          <>
                            <Typography variant="subtitle2">
                              Enable Disclaimer
                            </Typography>
                          </>
                        }
                        sx={{ justifyContent: "space-between" }}
                      />

                    {values?.disclaimer?.isActive && (
                      <>
                        <TextField
                          fullWidth
                          label="Title"
                          {...getFieldProps("disclaimer.content.en.title")}

                        />
                        <TextField
                          fullWidth
                          multiline
                          minRows={3}
                          label="Description"
                          {...getFieldProps("disclaimer.content.en.description")}

                        />
                        <TextField
                          fullWidth
                          label="Button Text"
                          {...getFieldProps("disclaimer.content.en.buttonText")}

                        />
                        <TextField
                          fullWidth
                          label="More Info Text"
                          {...getFieldProps("disclaimer.content.en.moreInfoText")}

                        />
                      </>
                    )}
                  </Stack>

                  <Stack spacing={2}>
                    <Label sx={{ mt: 3, py: 2, justifyContent: "flex-start" }}>
                      General
                    </Label>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(event) =>
                            setFieldValue(
                              "trackPrivateInfo",
                              event.target.checked
                            )
                          }
                          checked={values.trackPrivateInfo}
                        />
                      }
                      label={
                        <>
                          <Typography variant="subtitle2">
                            Track Info (e.g. name, fullname etc)
                          </Typography>
                        </>
                      }
                      sx={{ width: 1, mx: 0, justifyContent: "space-between" }}
                    />

                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(event) =>
                            setFieldValue("suspended", event.target.checked)
                          }
                          checked={values.suspended}
                        />
                      }
                      label={
                        <>
                          <Typography variant="subtitle2">
                            Suspended subscription
                          </Typography>
                        </>
                      }
                      sx={{ width: 1, mx: 0, justifyContent: "space-between" }}
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(event) =>
                            setFieldValue(
                              "usernameAllowed",
                              event.target.checked
                            )
                          }
                          checked={values.usernameAllowed}
                        />
                      }
                      label={
                        <>
                          <Typography variant="subtitle2">
                            Username Allowed
                          </Typography>
                        </>
                      }
                      sx={{ width: 1, mx: 0, justifyContent: "space-between" }}
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(event) =>
                            setFieldValue(
                              "matchCounsellorInOnboarding",
                              event.target.checked
                            )
                          }
                          checked={values.matchCounsellorInOnboarding}
                        />
                      }
                      label={
                        <>
                          <Typography variant="subtitle2">
                            Match counsellor in onboarding
                          </Typography>
                        </>
                      }
                      sx={{ width: 1, mx: 0, justifyContent: "space-between" }}
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(event) =>
                            setFieldValue(
                              "isCounsellingBoosterEnabled",
                              event.target.checked
                            )
                          }
                          checked={values.isCounsellingBoosterEnabled}
                        />
                      }
                      label={
                        <>
                          <Typography variant="subtitle2">
                            Counselling Booster Enabled
                          </Typography>
                        </>
                      }
                      sx={{ width: 1, mx: 0, justifyContent: "space-between" }}
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(event) =>
                            setFieldValue(
                              "companySpecificHotline",
                              event.target.checked
                            )
                          }
                          checked={values.companySpecificHotline}
                        />
                      }
                      label={
                        <>
                          <Typography variant="subtitle2">
                            Company Specific Hotline
                          </Typography>
                        </>
                      }
                      sx={{ width: 1, mx: 0, justifyContent: "space-between" }}
                    />

                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(event) =>
                            setFieldValue(
                              "enforceDemographicQuestions",
                              event?.target?.checked ?? false
                            )
                          }
                          checked={values?.enforceDemographicQuestions ?? false}
                        />
                      }
                      label={
                        <Typography variant="subtitle2">
                          Enforce demographic questions
                        </Typography>
                      }
                      sx={{ width: 1, mx: 0, justifyContent: "space-between" }}
                    />
                  </Stack>

                  <Box
                      sx={{mt: 3, display: "flex", justifyContent: "flex-end"}}
                  >
                    <LoadingButton
                        disabled={isView}
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                      {isView
                          ? "View Only"
                          : !isEdit
                              ? "Create Invitation Code"
                              : "Save Changes"}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
      <Modal
          open={modal.isOpen}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
        <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius: 2,
              p: 4,
            }}
        >
          {modal.type === 'confirm' ? (
              <>
                <Typography variant="h6" component="h2" align="center">
                  Alert
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  Are you sure you want to delete?
                </Typography>
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                      onClick={handleOkay}
                      variant="contained"
                      color="primary"
                      sx={{ mr: 1 }}
                  >
                    Okay
                  </Button>
                  <Button onClick={closeModal} variant="outlined" color="secondary">
                    Cancel
                  </Button>
                </Box>
              </>
          ) : (
              <>
                <Typography variant="h6" component="h2" align="center">
                  Add Domain
                </Typography>
                <TextField
                    id="modal-modal-input"
                    label="Your Input"
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2 }}
                    value={modal.newDomainValue || ''}
                    onChange={handleInputChange}
                />
                {isShowError && (
                    <Typography variant="h6" component="h2" align="center" color={"red"} mt={2}>
                      Please add valid domain
                    </Typography>
                )}
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                      onClick={() => handleOkay()}
                      variant="contained"
                      color="primary"
                      sx={{ mr: 1 }}
                  >
                    Okay
                  </Button>
                  <Button
                      onClick={() => closeModal()}
                      variant="outlined"
                      color="secondary"
                  >
                    Cancel
                  </Button>
                </Box>
              </>
          )}
        </Box>
      </Modal>
    </FormikProvider>
  );
}
