import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { PATH_DASHBOARD } from "../../../../routes/paths";
import { useSnackbar } from "notistack";

import { CCRolesPossibleVals } from "../../../../types/role/CCRoles";
import {
  isSuperAdminOrAccessToAllLanguages,
} from "../../../../utils/firebase/translationUtils";
import {
  ContentVersionsField,
} from "../../../../types/translations";
import { incrementContentVersion } from "../../../../utils/firebase/cloudFunctions";
import MoreMenu from "components/common/MoreMenu";
import { MultilingualLocale } from "types/multilingualLocales";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { deleteMultilingualLocale } from "utils/firebase/multilingualLocaleUtils";

type MultilingualLocaleRowType = {
  row: MultilingualLocale,
  currentRoles: CCRolesPossibleVals[]
}

export function MultilingualLocaleRow(props: MultilingualLocaleRowType) {
  const { enqueueSnackbar } = useSnackbar();
  const { row, currentRoles } = props;
  const [deletingId, setDeletingId] = useState<string>();
  const theme = useTheme();
  

  const navigate = useNavigate();

  const handleAppTranslationDelete = async () => {
    try {
      setDeletingId(row.id);

      if (window.confirm(`Do you really want to delete this locale (${ row.id })?`)) {
        await deleteMultilingualLocale(row.id)
        enqueueSnackbar("Locale Deleted Successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Delete Locale cancel by user", {
          variant: "error",
        });
      }

      await incrementContentVersion({ fieldName: ContentVersionsField.TRANSLATIONS_VERSION });
    } catch (e) {
      console.error("FailedToDeleteLocale", e);
      enqueueSnackbar("Failed to delete Locale", {
        variant: "error",
      });
    } finally {
      setDeletingId("");
    }
  }

  return (
      <TableRow sx={ { '& > *': { borderBottom: 'unset' } } }>
        <TableCell> 
          {row.id}
        </TableCell>
        <TableCell>
          { row.name }
        </TableCell>
        <TableCell> 
          { row.active ? (
            <Typography color={theme.palette.success.dark}>
              YES
            </Typography>
          ) : (
          <Typography color={theme.palette.error.dark}>
            NO
          </Typography>
          ) }
        </TableCell>
        <TableCell align="right">
          { isSuperAdminOrAccessToAllLanguages(currentRoles) &&
            (<MoreMenu
              deleting={ deletingId === row.id }
              onDelete={ () => handleAppTranslationDelete() }
              onEdit={ () => {
                navigate(
                  `${ PATH_DASHBOARD.multilingualLocales.root }/${ row.id }/edit`,
                  {
                    state: row
                  }
                );
              } }
              onView={ () => {
                navigate(
                  `${ PATH_DASHBOARD.multilingualLocales.root }/${ row.id }/view`,
                  {
                    state: row
                  }
                );
              } }
            />)
          }
        </TableCell>
      </TableRow>
  );
}
