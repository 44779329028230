import { useEffect, useRef, useState } from "react";
import firebase from "firebase";
import { db } from '../../contexts/FirebaseContext';
import { MultilingualLocale } from "types/multilingualLocales";

export function useGetMultilingualLocales({
  pageNo,
  pageSize,
  orderBy,
  sortingOrder,
  searchText,
  initialSnapShot = {},
}:{
  pageNo: number;
  pageSize: number;
  orderBy: string;
  sortingOrder: string;
  searchText: string;
  initialSnapShot: Record<string, any>;
}) {
    const [loading, setLoading] = useState<boolean>(true);
    const [isMoreLocalesAvailable, setIsMoreLocalesAvailable] = useState<boolean>(true);
    const lastSnapShot = useRef<any>(initialSnapShot);
    const [multilingualLocales = [], setMultilingualLocales] = useState<Array<MultilingualLocale>>([]);
  
    useEffect(() => {
      let dbRef: firebase.firestore.CollectionReference | firebase.firestore.Query = db
        .collection("multilingual_locales")
  
      if (searchText?.trim()?.length) {
        dbRef = dbRef.where("name", "==", searchText)
      } else {
        dbRef = dbRef
          .orderBy(orderBy, sortingOrder as firebase.firestore.OrderByDirection)
      }
  
      if (lastSnapShot?.current[pageNo]) {
        dbRef = dbRef.startAfter(lastSnapShot?.current[pageNo] ?? 0)
      }
  
      setLoading(true);
      let unsub = dbRef
        .limit(pageSize)
        .onSnapshot(async (snapshot) => {
          const snapShotKey = pageNo + 1
          lastSnapShot.current[snapShotKey] = snapshot.docs[snapshot.docs.length - 1]
          const dbMultilingualLocales = []
          for (const doc of snapshot.docs) {
            const locale = doc.data();
            
            dbMultilingualLocales.push({
              ...(locale as MultilingualLocale), id: doc.id
            });
          }
  
          setMultilingualLocales(dbMultilingualLocales ?? []);
          setIsMoreLocalesAvailable(dbMultilingualLocales.length === pageSize)
          setLoading(false);
        });
      return () => unsub();
    }, [pageNo, pageSize, orderBy, sortingOrder, searchText]);
  
  
    return { multilingualLocales, loading, isMoreLocalesAvailable, lastSnapShot: lastSnapShot.current };
}

export const localeToLowerCase = (locale: string) => {
  if (locale === "pt-BR") {
    return "pt-BR"
  }

  return locale.toLocaleLowerCase()
}

export function useGetAllMultilingualLocales() {
    const [multilingualLocalesCodeToName, setMultilingualLocalesCodeToName] = useState<Record<string, string>>({});
  
    useEffect(() => {
      let dbRef: firebase.firestore.CollectionReference | firebase.firestore.Query = db
        .collection("multilingual_locales")
  
      let unsub = dbRef
        .onSnapshot(async (snapshot) => {
          const dbLocalesCodeToName: Record<string, string> = {}

          for (const doc of snapshot.docs) {
            const locale = doc.data() as MultilingualLocale;
            const code = localeToLowerCase(locale.locale)
            dbLocalesCodeToName[code] = locale.name
          }
          setMultilingualLocalesCodeToName(dbLocalesCodeToName)
        });
      return () => unsub();
    }, []);
  
  
    return { multilingualLocalesCodeToName};
  }

export function useGetMultilingualLocaleById(localeId: string) {
  const [loading, setLoading] = useState<boolean>(true);
  const [multilingualLocale, setMultilingualLocale] = useState<MultilingualLocale>()

  useEffect(() => {
    if (!localeId?.length) {
      setLoading(false)
      return
    }

    setLoading(true)
    const unsub = db
      .collection("multilingual_locales")
      .doc(localeId)
      .onSnapshot(
        (doc) => {
          if (doc?.exists) {
            setMultilingualLocale({ ...doc.data(), id: doc.id } as MultilingualLocale)
          }
          setLoading(false)
        }
      );

    return () => unsub();
  }, [localeId]);

  return { multilingualLocale, loading }
}

export function addOrUpdateMultilingualLocale(localePayload: MultilingualLocale) {
  const { name, locale, active} = localePayload;
  return db.collection("multilingual_locales").doc(localePayload.id).set({name, locale, active}, { merge: true });
}

export function deleteMultilingualLocale(localeId: string) {
  return db.collection("multilingual_locales").doc(localeId).delete()
}
