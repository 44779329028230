import { Locale } from "../types/user";
import { MultilingualLocalesTypes } from "../types/translations";

export const BotsReflectionCategories = {
  // COMMITMENT: "commitment",
  // SITUATIONS: "situations",
  // CONFLICT_ANALYSIS: "conflict",
  // SWLO: "swlo",
  // ACTION_PLAN: "actionPlan",
  // MY_RESOURCES: "myResources",
  SMART_GOAL: "smartGoal",
  BUILD_IN_KINDNESS: "buildInKindness",
  AUTHENTICITY_WITH_OTHERS: "authenticityWithOthers",
  AUTHENTICITY_WITH_YOURSELF: "authenticityWithYourself",
  HIGH_QUALITY_RELATIONSHIPS: "highQualityRelationships",
  ABC_MODEL: "ABCModel",
  THOUGHT_RECORD: "thoughtRecord",
};

export const ASSESSMENTS_CATEGORIES = {
  AUTHENTICITY_WITH_OTHERS: "authenticityWithOthers",
  AUTHENTICITY_WITH_YOURSELF: "authenticityWithYourself",
  HIGH_QUALITY_RELATIONSHIPS: "highQualityRelationships",
};

export type ContentID = string;
export type ThemeId = string;
export type AssistantId = string;
export type AudioMeditationIDType = string;

export type Preview = {
  tabTitle: string;
  description: string;
  onlyForCounsellor: boolean;
};

export type Page = {
  thumbnail: string;
  markdown: string;
  exercise?: Array<string>;
};

export type ContentFromFirestore = {
  id: ContentID;
  assistantId: AssistantId;
  type: ContentUnitCategories;
  locale?: Locale;
  title: string;
  description?: string;
  duration?: string;
  overviewCards: Preview[];
  pages: Array<Page>;
  hidden?: boolean;
  previewHiddenToTherapists?: boolean;
  previewHiddenToUsers?: boolean;
  isMandatory?: boolean;
  isFeatured?: boolean;
  sortPlace?: number;
  tags: string[];
  isAIEnhancedModule?: boolean;
  recommendationIds?: ContentID[];
  childrenIds?: ContentID[];
  customisedChildren?: ModuleWithCustomisedFields[];
  themeId?: ThemeId;
  audioMeditationId?: AudioMeditationIDType;
  targetMultilingualLocales?: MultilingualLocalesTypes[];
  processedMultilingualLocales?: MultilingualLocalesTypes[];
  excludeFromSearchIndex?: boolean;
  disableBackgroundAudio?: boolean;
} & PracticeContentUnitFromFirestore &
  MeditationContentUnitFromFirestore &
  VideoContentUnitFromFirestore &
  ReflectionLinkContentUnitFromFirestore &
  ModuleLinkContentUnitFromFirestore &
  AssessmentLinkContentUnitFromFirestore & ScreenLinkContentUnitFromFirestore;

type PracticeContentUnitFromFirestore = {
  landbotId?: string;
};

type MeditationContentUnitFromFirestore = {
  audioFileUri?: string;
  mediaUrl?: string;
  category?: string;
  meditationType?: string;
};

type VideoContentUnitFromFirestore = {
  videoId?: string;
  mediaUrl?: string;
  thumbnail?: string;
};

type ModuleLinkContentUnitFromFirestore = {
  linkedModuleId?: ContentID;
};

type ReflectionLinkContentUnitFromFirestore = {
  reflectionCategory?: typeof BotsReflectionCategories;
};

type AssessmentLinkContentUnitFromFirestore = {
  assessmentCategory?: typeof ASSESSMENTS_CATEGORIES;
};

type ScreenLinkContentUnitFromFirestore = {
  screenId?: string;
  screenParams?: string;
};

export enum DisplayType {
  DESCRIPTIVE = "descriptive",
  SIMPLE = "simple",
  CAROUSEL = "carousel",
}

export type ContentFromFirestoreOptions = Pick<
  ContentFromFirestore,
  "id" | "title" | "type"
>;

export type ModuleWithCustomisedFields = {
  contentId: ContentID;
} & Partial<CustomisableFields>;

export type CustomisableFields = Pick<
  ModuleFromFirestore,
  "title" | "description" | "displayStyle"
>;

export type ModuleFromFirestore = ContentFromFirestore & {
  childrenIds: ContentID[];
  assessmentBotContentId?: string;
  displayStyle?: DisplayType;
};

export type ParentModuleFromFirestore = ContentFromFirestore & ModuleFromFirestore;

export enum ContentUnitCategories {
  MEDITATION = "meditation",
  PRACTICE = "practice",
  VIDEO = "video",
  MODULE_LINK = "moduleLink",
  REFLECTION_LINK = "reflectionLink",
  ASSESSMENT_LINK = "assessmentLink",
  BREATHING_MEDITATION = "breathingMeditation",
  BREATHING478 = "478Breathing",
  ARTICLE = "article",
  CHAT_VIDEO = "chatVideo",
  SCREEN_LINK = "screenLink",
}
