// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

export const PATH_DASHBOARD = {
  // After adding a new path, it's also necessary to add the
  // option to the side menu here --> src/layouts/dashboard/SidebarConfig.tsx
  root: ROOTS_DASHBOARD,
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    conversation: path(ROOTS_DASHBOARD, "/chat/:conversationKey"),
  },
  therapist: {
    root: path(ROOTS_DASHBOARD, "/therapist/"),
    list: path(ROOTS_DASHBOARD, "/therapist/list"),
    new: path(ROOTS_DASHBOARD, "/therapist/new"),
    edit: path(ROOTS_DASHBOARD, "/therapist/:therapistId/edit"),
    scheduledCalls: path(ROOTS_DASHBOARD, "/therapist/scheduled-calls"),
    scheduledCallsWithId: path(
      ROOTS_DASHBOARD,
      "/therapist/scheduled-calls/:therapistId"
    ),
    invoices: path(ROOTS_DASHBOARD, "/therapist/invoices"),
    viewInvoice: path(
      ROOTS_DASHBOARD,
      "/therapist/invoices/:therapistId/view/:invoiceNumber"
    ),
    filters: path(ROOTS_DASHBOARD, "/therapist/filters"),
    viewProgress: path(
      ROOTS_DASHBOARD,
      "/therapist/:therapistId/view-progress"
    ),
    chineseUserRelationships: {
      list: path(ROOTS_DASHBOARD, "/therapist/chinese-users"),
      new: path(ROOTS_DASHBOARD, "/therapist/chinese-users/new"),
      newSession: path(ROOTS_DASHBOARD, "/therapist/chinese-users/new-session"),
    },
  },
  users: {
    root: path(ROOTS_DASHBOARD, "/users/"),
    deleteUsersRequestList: path(ROOTS_DASHBOARD, "/users/delete-requests"),
    usersSearch: path(ROOTS_DASHBOARD, "/users/search"),
    usersSearchWithId: path(ROOTS_DASHBOARD, "/users/search/:userId"),
    migrate: path(ROOTS_DASHBOARD, "/users/migrate"),
    migrateCode: path(ROOTS_DASHBOARD, "/users/migrate/:code"),
    find: path(ROOTS_DASHBOARD, "/users/find"),
  },
  blendedCare: {
    root: path(ROOTS_DASHBOARD, "/blended-care/"),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, "/content/settings/"),
    save: path(ROOTS_DASHBOARD, "/content/settings/save"),
  },
  analytics: {
    root: path(ROOTS_DASHBOARD, "/analytics"),
  },
  chatAnalytics: {
    root: path(ROOTS_DASHBOARD, "/analytics/chat-analytics/"),
    list: path(ROOTS_DASHBOARD, "/analytics/chat-analytics/list"),
  },
  callAnalytics: {
    root: path(ROOTS_DASHBOARD, "/analytics/call-analytics/"),
    list: path(ROOTS_DASHBOARD, "/analytics/call-analytics/list"),
    editCall: path(ROOTS_DASHBOARD, "/analytics/call-analytics/edit-call"),
  },
  callRatings: {
    root: path(ROOTS_DASHBOARD, "/analytics/call-ratings"),
  },
  surveys: {
    root: path(ROOTS_DASHBOARD, "/surveys/"),
    list: path(ROOTS_DASHBOARD, "/surveys/list"),
    new: path(ROOTS_DASHBOARD, "/surveys/new"),
    edit: path(ROOTS_DASHBOARD, "/surveys/:surveyId/:language/edit"),
    view: path(ROOTS_DASHBOARD, "/surveys/:surveyId/:language/view"),
  },
  invitationCodes: {
    root: path(ROOTS_DASHBOARD, "/invitation-code"),
    newCode: path(ROOTS_DASHBOARD, "/invitation-code/new"),
    edit: path(ROOTS_DASHBOARD, "/invitation-code/:code/edit"),
    creditsPeriods: path(ROOTS_DASHBOARD, "/invitation-code/:code/credits"),
    qrCode: path(ROOTS_DASHBOARD, "/invitation-code/:code/qr-code"),
    duplicateCode: path(ROOTS_DASHBOARD, "/invitation-code/:code/duplicate"),
  },
  paymentLinks: {
    root: path(ROOTS_DASHBOARD, "/payment-links"),
    newLink: path(ROOTS_DASHBOARD, "/payment-links/new"),
    getLink: path(ROOTS_DASHBOARD, "/payment-links/:id/get-link"),
    edit: path(ROOTS_DASHBOARD, "/payment-links/:id/edit"),
    view: path(ROOTS_DASHBOARD, "/payment-links/:id/view"),
  },
  registrationCodes: {
    root: path(ROOTS_DASHBOARD, "/registration-code"),
    newCode: path(ROOTS_DASHBOARD, "/registration-code/new"),
    edit: path(ROOTS_DASHBOARD, "/registration-code/:code/edit"),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    newUser: path(ROOTS_DASHBOARD, "/user/new"),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    product: path(ROOTS_DASHBOARD, "/e-commerce/product/:name"),
    productById: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-air-force-1-ndestrukt"
    ),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    newProduct: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    editById: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-blazer-low-77-vintage/edit"
    ),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    invoice: path(ROOTS_DASHBOARD, "/e-commerce/invoice"),
  },
  appStableVersion: {
    root: path(ROOTS_DASHBOARD, "/app-stable-version"),
  },
  botData: {
    root: path(ROOTS_DASHBOARD, "/content/bot-data"),
  },
  botDataOverview: {
    root: path(ROOTS_DASHBOARD, "/content/bots-overview"),
  },
  botsRating: {
    root: path(ROOTS_DASHBOARD, "/content/bots-rating"),
  },
  botdTable: {
    root: path(ROOTS_DASHBOARD, "/content/bot-dtable"),
    newTable: path(ROOTS_DASHBOARD, "/content/bot-dtable/new"),
    editTable: path(ROOTS_DASHBOARD, "/content/bot-dtable"),
  },
  contentUnit: {
    root: path(ROOTS_DASHBOARD, "/content/content-units"),
  },
  contentModules: {
    root: path(ROOTS_DASHBOARD, "/content/content-modules"),
  },
  parentContentModules: {
    root: path(ROOTS_DASHBOARD, "/content/parent-content-modules"),
  },
  contentThemes: {
    root: path(ROOTS_DASHBOARD, "/content/content-themes"),
  },
  contentImages: {
    root: path(ROOTS_DASHBOARD, "/content/content-images"),
  },
  multilingualLocales: {
    root: path(ROOTS_DASHBOARD, "/content/multilingual-locales"),
    new: path(ROOTS_DASHBOARD, "/content/multilingual-locales/new"),
  },
  role: {
    root: path(ROOTS_DASHBOARD, "/role"),
  },
  iam: {
    root: path(ROOTS_DASHBOARD, "/iam"),
    role: path(ROOTS_DASHBOARD, "/iam/role"),
    mfa: path(ROOTS_DASHBOARD, "/iam/reset-mfa"),
    corporateDashboardPermissions: path(
      ROOTS_DASHBOARD,
      "/iam/corporate-dashboard-permissions"
    ),
  },
  auditLogs: {
    root: path(ROOTS_DASHBOARD, "/audit-logs"),
  },
  jsonKeysTranslations: {
    root: path(ROOTS_DASHBOARD, "/json-keys-translations"),
  },
  conferenceCall: {
    root: path(ROOTS_DASHBOARD, "/conference-call"),
    list: path(ROOTS_DASHBOARD, "/conference-call/list"),
    new: path(ROOTS_DASHBOARD, "/conference-call/new"),
    edit: path(ROOTS_DASHBOARD, "/conference-call/edit"),
  },
  appTranslations: {
    root: path(ROOTS_DASHBOARD, "/content/app-translations"),
  },
  missingTranslation: {
    root: path(ROOTS_DASHBOARD, "/content/missing-translations"),
  },
  appSideTranslations: {
    root: path(ROOTS_DASHBOARD, "/content/app-side-translations/"),
    list: path(ROOTS_DASHBOARD, "/content/app-side-translations/list"),
    new: path(ROOTS_DASHBOARD, "/content/app-side-translations/new"),
    edit: path(ROOTS_DASHBOARD, "/content/app-side-translations/:id/edit"),
    view: path(ROOTS_DASHBOARD, "/content/app-side-translations/:id/view"),
  },
  suggestedTranslations: {
    root: path(ROOTS_DASHBOARD, "/content/suggested-translations/"),
    new: path(ROOTS_DASHBOARD, "/content/suggested-translations/new"),
  },
  therapistSessions: {
    root: path(ROOTS_DASHBOARD, "/analytics/therapist-sessions"),
    list: path(ROOTS_DASHBOARD, "/analytics/therapist-sessions/list"),
    edit: path(ROOTS_DASHBOARD, "/analytics/therapist-sessions/edit"),
  },
  organisations: {
    root: path(ROOTS_DASHBOARD, "/organisation/"),
    list: path(ROOTS_DASHBOARD, "/organisation/list"),
    new: path(ROOTS_DASHBOARD, "/organisation/new"),
    edit: path(ROOTS_DASHBOARD, "/organisation/:organisationId/edit"),
    view: path(ROOTS_DASHBOARD, "/organisation/:organisationId/view"),
  },
  invitationCodeGroup: {
    root: path(ROOTS_DASHBOARD, "/invitation-codes-group/"),
    list: path(ROOTS_DASHBOARD, "/invitation-codes-group/list"),
    new: path(ROOTS_DASHBOARD, "/invitation-codes-group/new"),
    edit: path(ROOTS_DASHBOARD, "/invitation-codes-group/:groupId/edit"),
    view: path(ROOTS_DASHBOARD, "/invitation-codes-group/:groupId/view"),
  },
  releaseNotes: {
    root: path(ROOTS_DASHBOARD, "/release-notes/"),
    list: path(ROOTS_DASHBOARD, "/release-notes/list"),
    new: path(ROOTS_DASHBOARD, "/release-notes/new"),
    edit: path(ROOTS_DASHBOARD, "/release-notes/:id/edit"),
    view: path(ROOTS_DASHBOARD, "/release-notes/:id/view"),
  },
  privacyPolicy: {
    root: path(ROOTS_DASHBOARD, "/privacy-policy/"),
    list: path(ROOTS_DASHBOARD, "/privacy-policy/list"),
    new: path(ROOTS_DASHBOARD, "/privacy-policy/new"),
    edit: path(ROOTS_DASHBOARD, "/privacy-policy/:id/edit"),
    view: path(ROOTS_DASHBOARD, "/privacy-policy/:id/view"),
  },
  aiPrompts: {
    root: path(ROOTS_DASHBOARD, "/ai_prompts/"),
    list: path(ROOTS_DASHBOARD, "/ai_prompts/list"),
    new: path(ROOTS_DASHBOARD, "/ai_prompts/new"),
    edit: path(ROOTS_DASHBOARD, "/ai_prompts/:id/edit"),
    view: path(ROOTS_DASHBOARD, "/ai_prompts/:id/view"),
  },
  snippets: {
    root: path(ROOTS_DASHBOARD, "/snippets/"),
    list: path(ROOTS_DASHBOARD, "/snippets/list"),
    new: path(ROOTS_DASHBOARD, "/snippets/new"),
    edit: path(ROOTS_DASHBOARD, "/snippets/:id/edit"),
    view: path(ROOTS_DASHBOARD, "/snippets/:id/view"),
  },
  templates: {
    root: path(ROOTS_DASHBOARD, "/templates/"),
    list: path(ROOTS_DASHBOARD, "/templates/list"),
    new: path(ROOTS_DASHBOARD, "/templates/new"),
    edit: path(ROOTS_DASHBOARD, "/templates/:id/edit"),
    view: path(ROOTS_DASHBOARD, "/templates/:id/view"),
  },
  audioMeditationGenerations: {
    root: path(ROOTS_DASHBOARD, "/audio-meditation-generations/"),
    list: path(ROOTS_DASHBOARD, "/audio-meditation-generations/list"),
    new: path(ROOTS_DASHBOARD, "/audio-meditation-generations/new"),
    edit: path(ROOTS_DASHBOARD, "/audio-meditation-generations/:id/edit"),
    view: path(ROOTS_DASHBOARD, "/audio-meditation-generations/:id/view"),
    update: path(ROOTS_DASHBOARD, "/audio-meditation-generations/:id/update"),
  },
  userGeneratedAudioMeditations: {
    root: path(ROOTS_DASHBOARD, "/user-generated-audio-meditations/"),
    list: path(ROOTS_DASHBOARD, "/user-generated-audio-meditations/list"),
    view: path(ROOTS_DASHBOARD, "/user-generated-audio-meditations/:id/view"),
  },
  audioMeditationVoices: {
    root: path(ROOTS_DASHBOARD, "/audio-meditation-voices/"),
    list: path(ROOTS_DASHBOARD, "/audio-meditation-voices/list"),
    new: path(ROOTS_DASHBOARD, "/audio-meditation-voices/new"),
    edit: path(ROOTS_DASHBOARD, "/audio-meditation-voices/:id/edit"),
    view: path(ROOTS_DASHBOARD, "/audio-meditation-voices/:id/view"),
  },
  changelog: {
    root: path(ROOTS_DASHBOARD, "/changelog-explorer"),
  },
  notifications: {
    root: path(ROOTS_DASHBOARD, "/notifications/"),
    tool: path(ROOTS_DASHBOARD, "/notifications/tool"),
    history: path(ROOTS_DASHBOARD, "/notifications/history"),
  },
  accountMFA: "/account/mfa",
};
