import React from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// material
import { LoadingButton } from "@mui/lab";
import { Box, Card, Checkbox, FormControlLabel, Grid, Stack, TextField } from "@mui/material";


import {
    ContentVersionsField,
} from "../../../../types/translations";
import { incrementContentVersion } from "../../../../utils/firebase/cloudFunctions";
import { MultilingualLocale } from "types/multilingualLocales";
import { addOrUpdateMultilingualLocale } from "utils/firebase/multilingualLocaleUtils";

// ----------------------------------------------------------------------

type MultilingualFormProps = {
  isEdit: boolean;
  isView?: boolean;
  multilingualLocale?: MultilingualLocale;
};

export default function MultilingualLocaleForm({
    isView,
    isEdit,
    multilingualLocale,
}: MultilingualFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const ReleaseNoteSchema = Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required().required("Name is required"),
    locale: Yup.string().required().required("Locale is required"),
    active: Yup.boolean().required().required("Active is required"),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
        id: multilingualLocale?.id ?? "",
        active: multilingualLocale?.active ?? false,
        locale: multilingualLocale?.locale ?? "",
        name: multilingualLocale?.name ?? "",
    },

    validationSchema: ReleaseNoteSchema,
    onSubmit: async (values) => {
      try {
        const newTranslationPayload: MultilingualLocale = {
        id: values.id,
        active: values.active,
        locale: values.locale,
        name: values.name,
        };

        await addOrUpdateMultilingualLocale(newTranslationPayload)
        

        await incrementContentVersion({ fieldName: ContentVersionsField.MULTILINGUAL_LOCALES_VERSION });

        enqueueSnackbar('Saved successfully!', { variant: 'success' });
        navigate(-1)
      } catch (e) {
        console.error(e)
        enqueueSnackbar('Failed to save', { variant: 'error' });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  let submitBtnTitle = "Create New Locale";
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit) {
    submitBtnTitle = "Save Changes";
  }

  return (
    <FormikProvider value={ formik }>
      <Form noValidate autoComplete="off" onSubmit={ handleSubmit }>
        <fieldset disabled={ isView } style={ { border: "none" } }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 }>
              <Card sx={ { p: 3 } }>
                <Stack spacing={ 3 }>
                  <TextField
                    fullWidth
                    label="ID"
                    disabled={ isView }
                    { ...getFieldProps("id") }
                    error={ Boolean(touched.id && errors.id) }
                    helperText={ touched.id && errors.id }
                  />
                  <TextField
                    fullWidth
                    label="Name"
                    disabled={ isView }
                    { ...getFieldProps("name") }
                    error={ Boolean(touched.name && errors.name) }
                    helperText={ touched.name && errors.name }
                  />
                  <TextField
                    fullWidth
                    label="Locale Code"
                    disabled={ isView }
                    { ...getFieldProps("locale") }
                    error={ Boolean(touched.locale && errors.locale) }
                    helperText={ touched.locale && errors.locale }
                  />
                  <FormControlLabel
                        control={
                          <Checkbox
                            {...getFieldProps("active")}
                            checked={getFieldProps("active").value}
                          />
                        }
                        label="is Active?"
                      />

                  <Box sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={ isSubmitting }
                    >
                      { submitBtnTitle }
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
