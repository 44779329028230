import * as Yup from "yup";

const SupportContactSchema = Yup.array(
  Yup.object().shape({
    id: Yup.string(),
    title: Yup.string().required("title is required."),
    numbers: Yup.array(
      Yup.object().shape({
        id: Yup.string(),
        title: Yup.string()
          .min(2, "minimum length should be 2 characters.")
          .required("name is required."),
        number: Yup.string().max(21).required("number is required."),
      })
    ),
  })
);

const ShortDescSchema = Yup.object().shape({
  id: Yup.string(),
  value: Yup.string().min(2, "minimum length should be 2 characters."),
});

export const CreditsPeriodsFormSchema = Yup.object().shape({
  allowedCredits: Yup.number().required(
    "number of credits allowed is required."
  ),
  unlimitedCredits: Yup.boolean().default(false).required(),
  active: Yup.boolean().default(true).required(),
});

export const NewInvitationCodeSchema = Yup.object().shape({
  code: Yup.string().required("code is required."),
  usageLimit: Yup.number().required("usage Limit is required."),
  timesChecked: Yup.number().default(0),
  trackPrivateInfo: Yup.boolean().default(false).required(),
  suspended: Yup.boolean().default(false).required(),
  usernameAllowed: Yup.boolean().default(false).required(),
  matchCounsellorInOnboardingusernameAllowed: Yup.boolean().default(false),
  expiresAt: Yup.string().default(function () {
    return new Date().toISOString().split("T")[0];
  }),
  createdAt: Yup.string().default(function () {
    return new Date().toISOString().split("T")[0];
  }),
  translations: Yup.object().shape({
    enableTranslations: Yup.boolean(),
    usersEnabled: Yup.array(Yup.string()),
    versionsEnabled: Yup.array(Yup.string()),
  }),
  videoCall: Yup.object().shape({
    enableVideoCall: Yup.boolean(),
    usersEnabled: Yup.array(Yup.string()),
    versionsEnabled: Yup.array(Yup.string()),
  }),
  chat: Yup.object().shape({
    enableChat: Yup.boolean(),
    usersEnabled: Yup.array(Yup.string()),
    versionsEnabled: Yup.array(Yup.string()),
  }),
  questions: Yup.array(
    Yup.object().shape({
      id: Yup.string(),
      language: Yup.string().required(),
      question: Yup.string().required("question is required."),
      answers: Yup.array(
        Yup.object().shape({
          id: Yup.string(),
          answer: Yup.string().required("Answer is required."),
        })
      ),
    })
  ),
  supportContacts: Yup.object().shape({
    id: Yup.string(),
    short_desc: Yup.object().shape({
      en: ShortDescSchema.required("english translation is required"),
      es: ShortDescSchema,
      de: ShortDescSchema,
      fr: ShortDescSchema,
    }),
    en: SupportContactSchema,
    fr: SupportContactSchema,
    de: SupportContactSchema,
    es: SupportContactSchema,
  }),
  exclusiveSituations: Yup.array(Yup.string()),
  companySpecificHotline: Yup.boolean(),
  disclaimer: Yup.object().shape({
    isActive: Yup.boolean().default(false).required(),
    content: Yup.object().shape({
      en: Yup.object().shape({
        title: Yup.string(),
        description: Yup.string(),
        buttonText: Yup.string(),
      }),
    }),
  }),
});
